/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"

const Layout = ({ children }) => {
  return (
    <>
      <div className="container">
        
        <Header />

        <main>
          {children}
        </main>

        <footer>
        <div class="social-icons">
          <a href="mailto:iceberglickingsociety@gmail.com" class="social-icon">iceberglickingsociety@gmail.com</a>
          <a href="https://www.facebook.com/icebergsociety/" class="social-icon" target="_blank" rel="noopener noreferrer">facebook</a>
          <a href="https://www.instagram.com/iceberglickingsociety/" class="social-icon" target="_blank" rel="noopener noreferrer">instagram</a>
          <a href="https://twitter.com/IcebergLicking" class="social-icon" target="_blank" rel="noopener noreferrer">twitter</a>
          <a href="https://www.linkedin.com/company/43332423" class="social-icon" target="_blank" rel="noopener noreferrer">linkedIn</a>
        </div>
          © {new Date().getFullYear()}{` `}
          <a href="/">Gumnut Films</a>
        </footer>

      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
