import PropTypes from "prop-types"
import React from "react"
import "./header.css"
import logo from "../images/logo.svg"
import { Link } from "gatsby"


const Header = ({ siteTitle }) => (
  <header>
    <Link to="/">
      <img src={logo} className="logo" alt="logo" />
    </Link>
    <h2 className="page-heading">Together we can stop the melt</h2>
    <nav>
      <a className="nav-link" href="https://teespring.com/stores/iceberg-licking-society" target="_blank" rel="noopener noreferrer">Shop</a>
      <Link className="nav-link" to="/join">Join</Link>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
